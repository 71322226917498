<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('profile.testing-page.title')"></ProfileNavbar>
    <div class="clearfix">
      <div class="">
        <div class="col-md-12">
          <h2 class="trial-tests-title">
            {{$t('olympiad_mock_tests')}}
          </h2>
          <div class="trial-tests-list">
            <div class="trial-tests-item">
              <div class="trial-tests-item-left">
                <h3 class="trial-tests-item-title">
                  {{$t('olympiad_mock_tests_title')}}
                </h3>
                <p class="trial-tests-item-desc">
                  <span>
                    {{$t('olympiad_mock_tests_number_questions')}}40
                  </span>
<!--                  <span>-->
<!--                    {{$t('olympiad_mock_tests_tries')}}5-->
<!--                  </span>-->
<!--                  <span>-->
<!--                    {{$t('olympiad_mock_tests_price')}}2000 ₸-->
<!--                  </span>-->
                </p>
                <div class="trial-tests-item-subjects">
                  <p>
                    {{$t('olympiad_mock_tests_subject_1')}}
                  </p>
                  <p>
                    {{$t('olympiad_mock_tests_subject_2')}}
                  </p>
                </div>
              </div>
              <div class="trial-tests-item-right">
                <div class="test-tries-left">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 6.84375C15.5 6.84375 16.6419 6.28125 12.5 6.28125C11.0166 6.28125 9.5666 6.72112 8.33323 7.54523C7.09986 8.36934 6.13856 9.54068 5.57091 10.9111C5.00325 12.2816 4.85472 13.7896 5.14411 15.2444C5.4335 16.6993 6.14781 18.0357 7.1967 19.0846C8.2456 20.1334 9.58197 20.8478 11.0368 21.1371C12.4917 21.4265 13.9997 21.278 15.3701 20.7103C16.7406 20.1427 17.9119 19.1814 18.736 17.948C19.5601 16.7147 20 15.2646 20 13.7812" stroke="#E24C4B" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path d="M12.5 2.71875L16.25 6.46875L12.5 10.2188" stroke="#E24C4B" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{$t('testing.test-tries', { tries: 3})}}
                </div>
                <a class="trial-tests-item-link" :href="$t('olympiad_mock_tests_url_1')">
                  {{$t('olympiad_mock_tests_link_1')}}2 000 ₸
                </a>
              </div>
            </div>
            <div class="trial-tests-item">
              <div class="trial-tests-item-left">
                <h3 class="trial-tests-item-title">
                  {{$t('olympiad_mock_tests_title_2')}}
                </h3>
                <p class="trial-tests-item-desc">
                  <span>
                    {{$t('olympiad_mock_tests_number_questions')}}100
                  </span>
<!--                  <span>-->
<!--                    {{$t('olympiad_mock_tests_tries')}}5-->
<!--                  </span>-->
<!--                  <span>-->
<!--                    {{$t('olympiad_mock_tests_price')}}3000 ₸-->
<!--                  </span>-->
                </p>
                <div class="trial-tests-item-subjects">
                  <p>
                    {{$t('olympiad_mock_tests_subject_3')}}
                  </p>
                  <p>
                    {{$t('olympiad_mock_tests_subject_4')}}
                  </p>
                  <p>
                    {{$t('olympiad_mock_tests_subject_5')}}
                  </p>
                  <p>
                    {{$t('olympiad_mock_tests_subject_6')}}
                  </p>
                </div>
              </div>
              <div class="trial-tests-item-right">
                <div class="test-tries-left">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 6.84375C15.5 6.84375 16.6419 6.28125 12.5 6.28125C11.0166 6.28125 9.5666 6.72112 8.33323 7.54523C7.09986 8.36934 6.13856 9.54068 5.57091 10.9111C5.00325 12.2816 4.85472 13.7896 5.14411 15.2444C5.4335 16.6993 6.14781 18.0357 7.1967 19.0846C8.2456 20.1334 9.58197 20.8478 11.0368 21.1371C12.4917 21.4265 13.9997 21.278 15.3701 20.7103C16.7406 20.1427 17.9119 19.1814 18.736 17.948C19.5601 16.7147 20 15.2646 20 13.7812" stroke="#E24C4B" stroke-miterlimit="10" stroke-linecap="round"/>
                    <path d="M12.5 2.71875L16.25 6.46875L12.5 10.2188" stroke="#E24C4B" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{$t('testing.test-tries', { tries: 3})}}
                </div>
                <a class="trial-tests-item-link" :href="$t('olympiad_mock_tests_url_2')">
                  {{$t('olympiad_mock_tests_link_1')}}3 000 ₸
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import moment from 'moment';
import VueMoment from "vue-moment";
import SchoolCard from '@/components/testing/SchoolCard'

export default {
  name: 'TrialTests',
  components: {
    ProfileNavbar,
    SchoolCard,
  },
  computed: {},
  data() {
    return {

    }
  },
  methods: {

  },
  mounted: {

  }
}
</script>

<style>

</style>
